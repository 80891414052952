.OrderId{
    font-size: 1.4rem;
    margin-right: 1rem;
}

.Badge{
    background-color: #6a6a6a;
    color: #fff;
    width: fit-content;
    width: -moz-fit-content;
    border-radius: 0.7rem;
    padding: 0.1rem 0.7rem;
    font-size: 0.9rem;
}

.CustomerCard{
    padding: 1.1rem 1.4rem;
}

// .unPaid.Badge{
//     background-color: red;
// }

// .Paid.Badge{
//     background-color: green;
// }

.Date{
    font-size: 0.85rem;
}