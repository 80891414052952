
.small-text{
    font-size: 12px;
    color: #5c5c5c;
}

.edit-btns button,
.edit-btns a{
    margin-right: 20px;
}

table tr{
    vertical-align: middle;
}