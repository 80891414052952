@media (orientation: portrait) {

    .product-detail-info .cart--btns button {
        width: 100%;
        text-transform: uppercase;
    }
    .product-detail .cart--btns .buy-now{
        background: transparent;
        color: #e42528;
        border: 1.5px solid #e42528;
        margin-bottom: 0.6rem;
    }
    ul.highlights{
        padding-left: 0.9rem;
    }

    footer h3 {
        font-size: 21px;
        margin-bottom: 0rem;
    }

    footer .row{
        margin-bottom: 1.1rem;
    }
    .follow-us .columns{
        margin-bottom: 0.9rem;
    }

}