a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}

* {
    font-family: 'Roboto', sans-serif;
}

.bg-primary {
    background-color: #e42529 !important;
}

.s-mobile {
    display: none;
}

.btn-primary {
    color: #fff;
    background-color: #e42529;
    border-color: #e42529;
}

.navbar-light .navbar-nav .nav-link {
    color: #000 !important;
}

nav .primary-bg .nav-link:hover,
nav .primary-bg .nav-link:focus {
    color: #fff !important;
}

header {
    background-color: #e42529;
}

header nav {
    height: 62px;
}

header .navbar-brand img {
    width: 178px;
    height: auto;
}

.search-box {
    width: calc(38vw - 30px);
}

button.btn-primary:hover,
button.btn-primary:focus,
button.btn-primary:focus-visible {
    background-color: #e42529;
    border-color: #e42529;
}

nav {
    box-shadow: 0 1px 1px 0 #000;
    min-height: 56px;
}

nav .instore {
    padding-bottom: 0;
    padding-top: 0px;
}

nav .instore li {
    font-size: 12px;
}

nav .instore li svg {
    fill: #e42529;
    height: 15px;
    width: auto;
    margin-right: 4px;
}

.primary-bg svg {
    fill: #fff;
}

.main-price {
    color: #000;
    font-size: 18px;
    font-weight: bold;
}

.original-price {
    font-size: 14px;
    text-decoration: line-through;
    opacity: 0.7;
}

.discount {
    font-size: 15px;
}

.mi-partner {
    width: 120px;
    height: auto;
}

svg {
    width: 23px;
    height: auto;
}

.icon-div {
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.product img.product-img {
    height: 200px !important;
    width: auto !important;
    margin-bottom: 14px;
}

.product-section {
    padding-top: 19px !important;
}

.product-image {
    text-align: center;
}

.section {
    padding-top: 60px;
    padding-bottom: 60px;
}

.hero {
    padding-bottom: 30px;
}

.product-detail .product-title {
    font-size: 24px;
    margin-bottom: 4px;
}

.product-detail .price {
    font-size: 29px;
    font-weight: 600;
    color: #e42529;
    margin-bottom: 5px;
}

.price.slashed {
    color: #7e7e7e;
    font-size: 17px;
    text-decoration: line-through;
    margin-left: 10px;
}

.product-detail h3 {
    font-size: 17px;
}

.product-detail .desc {
    font-size: 15px;
}

.product-detail .desc ul {
    padding-left: 18px;
}

.product-detail .offers {
    margin-bottom: 24px;
}

.product-detail .offers .gain {
    margin-bottom: 6px;
    font-weight: 500;
}

.product-detail .offers .gain svg {
    fill: #393939;
    height: 19px;
    position: relative;
    top: -1px;
}

.product-detail .offers ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
}

.product-detail .offers li {
    margin-bottom: 8px;
    font-size: 13.7px;
    line-height: 13px;
}

.product-detail .offers li:last-child {
    margin-bottom: 3px;
}

.product-detail a.offerText {
    color: #0d6efd !important;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
}

.product-detail a.offerText svg {
    height: 14px;
    width: auto;
    margin-left: 3px;
}

.product-detail .cart--btns button {
    width: 40%;
    margin-right: 10px;
}

.product-detail .cart--btns .buy-now {
    background: #393939;
    color: #fff;
}

.cart-page .product-container {
    box-shadow: 0 1px 2px 1px #000;
    border-radius: 0.5rem;
    padding: 26px 21px;
}

.cart-page .product {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
}

.cart-page .product .product-image {
    margin-right: 20px;
}

.cart-page .product img {
    height: 158px;
}

.cart-page .product .info {
    width: 70%;
}

.cart-page .product h4 {
    position: absolute;
    right: 0;
}

.cart-page .product .removed-product {
    transition: 0.6s;
    display: none;
}

.cart-page .product .quantity-box-container {
    position: absolute;
    right: 0;
    bottom: 0;
}

.cart-page .product .quantity-box-container div.quantity-box {
    display: flex;
    align-items: center;
    border: 1px solid #bebebe;
}

.cart-page .product .quantity-box-container div.quantity-box button {
    background-color: #bebebe;
    color: #000;
    width: 32px;
    border: 1px solid #bebebe;
}

.cart-page .product .quantity-box-container div.quantity-box button.decrement {
    margin-right: 8px;
}

.cart-page .product .quantity-box-container div.quantity-box button.increment {
    margin-left: 8px;
}

footer {
    background-color: #f6f6f6;
    padding-top: 44px;
}

footer a {
    color: #0457c8 !important;
    cursor: pointer;
}

footer .social-links {
    display: flex;
    align-items: center;
}

footer .social-links li {
    margin-right: 10px;
}

footer .social-links img {
    width: 35px;
    height: auto;
}

footer .app img {
    width: auto;
    height: 49px;
}

footer h3,
footer li,
footer p {
    color: #000;
}

footer h4 {
    font-size: 21px;
    margin-bottom: 11px;
}

footer h3 {
    font-size: 21px;
    margin-bottom: 15px;
}

footer li {
    font-size: 15px;
    margin-bottom: 6px;
}

footer .copyright {
    padding-top: 12px;
}

footer .copyright p {
    font-size: 13.9px;
}

.modal-body input {
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
}

.strike {
    position: absolute;
    background-color: #c5cbd5;
    top: 50%;
    left: 0px;
    right: 0px;
    height: 1px;
}

.strike-div {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.strike-div span {
    background-color: #fff;
    padding: 4px 20px;
    margin: 5px 0;
    z-index: 1;
    font-size: 15px;
    font-weight: 500;
}

.star {
    height: 16px;
    width: auto;
    margin-right: 1px;
}

.modal-input {
    margin-bottom: 16px;
}

.modal-body .modal-input:nth-child(3) {
    margin-top: 27px;
    margin-bottom: 5px;
}

.modal-body button {
    width: 100%;
    border-radius: 0px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.icon-div a {
    color: #fff;
}

.services-provided {
    padding: 15px;
    min-width: 263px;
}

.services-provided address {
    padding-top: 1px;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 0px;
    padding-bottom: 7px;
}

.services-provided ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding: 0;
}

.services-provided ul li img {
    width: 55px;
    height: auto;
}

.find-us-menu {
    width: 285px;
}

.services-provided ul li figcaption {
    color: #212529;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
}

.services-provided .find-us {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    margin-top: 14px;
}

.variant-row {
    margin-top: 25px;
}

.variant-row h5 {
    font-size: 15px;
    color: #707070;
    margin-bottom: 8px;
}

.variant-row h5 span {
    font-size: 14.2px;
}

.variants img {
    width: 42px;
    height: auto;
}

.variants .variant {
    margin-right: 12px;
    border: 2px solid #999;
    padding: 0.1px 3px;
    color: black;
    cursor: pointer;
}

.variants .variant.selected {
    border-color: #e42529;
    color: #e42529;
}

.know-more-div {
    display: flex;
    align-items: center;
}

.know-more-div img {
    height: 22px;
    width: auto;
    margin-right: 28px;
}

.know-more-div p {
    font-size: 14px;
}

.breadcrumb {
    margin-bottom: 14px;
}

.breadcrumb li {
    font-size: 13px;
    margin-right: 9px;
    color: gray;
}

.breadcrumb li.right-arrow svg {
    width: auto;
    height: 12px;
}

.slashed {
    color: #a2a2a2;
    font-size: 18px;
    text-decoration: line-through;
    margin-left: 11px;
}

.offer {
    color: #e42529;
    font-size: 16px;
    margin-left: 7px;
}

.rating {
    font-size: 14px;
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
    color: gray;
}

.rating span {
    color: #e42529;
    margin-right: 6px;
    margin-left: 4px;
    font-weight: 600;
}

.rating span svg {
    width: 15px;
    height: auto;
}

.product-detail-info {
    position: relative;
}

.product-detail-info .share-panel {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}

.product-detail-info .share-panel svg {
    color: gray;
}

.product-detail-info .share-panel svg:first-child {
    margin-right: 13px;
}

.delivery-col {
    margin-top: 21px;
}

.product-section .splide__track--nav>.splide__list>.splide__slide.is-active {
    border: 2px solid #e42529;
}

.product-thumbnail .splide__slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-main-image {
    margin-bottom: 19px;
}

.product-thumbnail .img-container img {
    height: 75px;
}

.product-detail-info h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.product-detail-info h4 {
    font-size: 20px;
}

.product-detail-info .cart--btns button {
    width: 23%;
    text-transform: uppercase;
}

.product-detail .sticky-top {
    top: 86px;
    z-index: 1;
}

.specs-table div {
    margin-top: 14px;
}

.specs-table div div:first-child {
    margin-top: 0px;
}

table.specs tr {
    font-size: 15px;
    vertical-align: baseline;
    line-height: 28px;
}

table.specs tr td:first-child {
    width: 25%;
    color: gray;
}

table.specs tr td:last-child {
    width: 75%;
}

.title-div {
    text-align: center;
}

.title-div h3 {
    margin-bottom: 56px;
}

.explore img {
    margin-bottom: 29px;
}

.explore h3 {
    margin-bottom: 56px;
}

.sliding--div {
    display: none;
    margin-top: 11px;
    margin-bottom: 10px;
    text-align: center;
}

.sliding--div p {
    font-size: 13px;
    margin-bottom: 0px;
}

.sliding--div img {
    margin-bottom: 4px;
}

.splide__arrow svg {
    fill: #dedede !important;
}

.card-body a {
    text-decoration: none;
}

.card-title {
    font-size: 14px;
    color: #000;
    line-height: 17px;
}

span.price {
    font-size: 16px;
    font-weight: 600;
}

.primary-bg .nav-link {
    color: #fff;
}

.product-col {
    display: flex;
}

.product-col .product-card {
    flex: 1 1 0;
}

.pt-60 {
    padding-top: 60px;
}

.pb-60 {
    padding-bottom: 60px;
}

/* product page  */

.splide__slide img {
    height: inherit;
    object-fit: contain;
}


.variant-row h5.ind0 {
    display: block
}

// .splide__slide img {
//     display: block !important;
// }

.splide__slide {
    background-image: unset !important;
}

.media-gallery img {
    height: 200px;
    width: auto;
    object-fit: contain;
}

.media-gallery .img-container {
    padding: 10px 2px;
    background-color: #eee;
    text-align: center;
}

.isVariant.card {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 0;
}

.variants.card {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.product-admin .card-header {
    font-weight: 500;
    font-size: 17px;
}

.product-admin .card-header p {
    font-weight: 400;
    font-size: 16px;
}

.create-product {
    font-size: 21px;
}

.z-1 {
    z-index: 1;
}

.menuName-1 {
    padding-left: 1.5em;
}

.menuName-2 {
    padding-left: 1.5em;
}

.editMenu {
    justify-content: space-between;
}

.editMenu svg {
    pointer-events: none;
}

.secondary-menu .nav-link {
    color: #000;
}

.secondary-menu {

    .search-box,
    form {
        width: 100%;
        display: none;
    }
}

nav.secondary-menu {
    box-shadow: 0 1px 1px 0 #e1e1e1;
}

.image-upload-wrap {
    border: 4px dashed #e7e7e7;
    position: relative;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.drag-text p {
    margin: 0;
    padding: 33px 0px;
}

.product-admin-nav {
    box-shadow: none;
}

.cart-container img {
    height: 109px;
    object-fit: contain;

}

.cart-container .row {
    align-items: center;
}

.filters .card-title {
    font-size: 20px;
    padding: 4px 0px;
}

.filters h4 {
    font-size: 1em;
}

.productListRow .card {
    border: unset;
}

.addToCart svg {
    padding-left: 4px;
}





.product-rate {}

.strike-text {
    text-decoration: line-through;
    font-size: 15px;
    margin-left: 10px;
}

.productRating {
    display: flex;
    align-items: center;
}

.productRating .star {
    display: flex;
    background: #388e3c;
    align-items: center;
    width: fit-content;
    padding: 10px 7px;
    color: #fff;
    border-radius: 3px;
    margin-bottom: 0;
}

.productRating img {
    width: 14px;
    margin-left: 4px;
}

.starRatings {
    margin-left: 10px;
    margin-bottom: 0;
}

.lp-products .productSection {
    text-align: center;
}

.lp-products .card-title,
.lp-products .product-rate {
    text-align: left;
}

.productLink {
    background: #ea452e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    line-height: 30px;
}

.bg-gray {
    background: #f6f6f6;
}

.filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
    margin-top: 21px;
}

.filter button {
    background-color: #fff;
    color: #000;
    border-radius: 17px;
}

.productTitle {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
}

.pricee {
    font-weight: 500;
    font-size: 1.5rem;
}

.primary-text {
    color: #e52023;
}

.filter .form-select {
    width: 200px;
}

.collectionCard {
    display: flex;
    padding: 0.2rem;

    .card {
        border-radius: 20px;
        margin-bottom: 27px;
        flex: 1 1 0;
    }

    .priceBody {
        display: flex;
    }

    .card-body {
        padding: 19px 7px 7px 7px;
        border: 1px solid #f1f1f1;
    }

    .card-img-top {
        height: 275px;
        object-fit: contain;
        width: 100%;
        background-color: #f1f1f1;
        padding-top: 14%;
        padding-bottom: 14%;
    }

    a.btn {
        border-radius: 19px;
        border: 1px solid;
    }

    .card-footer {
        background-color: #fff;
        border: unset;
        padding: 0;
    }
}

.heart {
    position: absolute;
    right: 4%;
    top: 3%;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
    width: 34px;
    height: 34px;
    text-align: center;

    svg {
        width: 18px;
        height: auto;
    }
}

@media only screen and (min-width: 767px) {
    .pricee {
        // float: right;
    }
}

@media only screen and (max-width: 767px) {

    .secondary-menu {

        .search-box,
        form {
            display: block;
        }
    }

    .primary-nav {

        .search-box,
        form {
            display: none;
        }
    }

}

.featuredd img {
    height: 32px;
    width: auto;
    margin: 1rem;
}

#showMedia {
    img {
        height: 10rem;
        object-fit: contain;
    }

    .singleMedia {
        text-align: center;
        padding: 40px 0px;
        background-color: #f1f1f1;
    }
}

.btn-tag {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.megaMenu {

    width: 100%;
    min-width: 50rem;

    img {
        width: auto;
        height: 84px;
        margin-left: 12px;
    }

    >li {
        width: 25%;
        float: left;

        .subMenuParent {
            font-weight: 500;
            color: #000;
            text-transform: uppercase;
            font-size: 14.3px;
            letter-spacing: 0.1px;
            position: relative;

            span {
                margin-right: 10px;
            }
        }

        .subMenuParent::after {
            content: "";
            // background-image: url(/assets/images/chevronRight.svg);
            position: absolute;
            width: 17px;
            height: 17px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            top: 5.9px;

        }

    }

    .megaChild {
        list-style-type: none;
        padding-left: 0px;
    }

}

.singleMedia {
    position: relative;
}

.imagePosition {
    position: absolute;
    top: 11px;
    right: 9px;
    background-color: #388e3c;
    color: #fff;
    aspect-ratio: 1/1;
    width: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagePosition.delete {
    right: 54px;
    background-color: unset;
    color: #000;
}

.imagePosition.update {
    right: 114px;
    background-color: unset;
    color: #000;
}

// image gallery slider
.active {
    border: 2px solid #e42529;
}

.productSlider {
    position: relative;
    height: 50vh;
    display: flex;
    justify-content: center;

    .rowtwo {
        img {
            height: 80px;
            width: auto;
        }
    }
}

.left_1::after {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: red;
    width: 80px;
    height: 30px;
    cursor: pointer;
    z-index: 99999;
    display: block;
}

.product-thumbnail .img-container {
    padding: 1px 9px;
}

// image gallery slider ends

.page-item.active .page-link {
    background-color: #dc3545;
    border-color: #dc3545;
}


#myPortal {
    pointer-events: none;
}


.heading.card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        margin-right: 1em;
    }

    a:last-child {
        margin-right: 0;
    }
}

.fullpage {
    display: none;

    svg {
        width: 39px;
        height: auto;
        display: block;
    }
}

.fullpage.open {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    z-index: 2;
    display: block;
    overflow-y: scroll;
}

.sticky-top {
    z-index: 4 !important;
}


// 
.magnifier {
    position: relative;
}

.magnifier-image {
    position: relative;
    overflow: hidden;
}

.magnifier-image img {
    display: block;
    width: 100%;
    height: auto;
}

.magnifier-zoom {
    position: absolute;
    top: 0;
    right: 100%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 1;
}

.collections .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blackActive {

    color: #000 !important;
}



.wishlist-heart {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative;
    transition: transform 0.2s ease-in-out;
    margin-right: 10px;
}

.wishlist-heart.liked {
    transform: scale(1.3);
}

.wishlist-heart .heartss {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #000;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
}

.wishlist-heart.liked .heartss {
    opacity: 1;
    color: red;
}

.alert {
    position: absolute;
}

.alert-message {
    margin-top: 10px;
    background-color: #e8f5e9;
    color: #43a047;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    top: 50%;
    left: 0;
    width: 100%;
}

#loginModal {
    p.register-text {
        text-align: center;
        margin-top: 1.3rem;

        a {
            color: #00f;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .modal-body {
        padding-bottom: 0.5rem;
    }

}

.tags-btn {
    background-color: #000;
    border-color: #000;
    padding: 1px 1px 1px 8px;
    font-size: 0.9rem;
    cursor: unset !important;
    margin-right: 0.6rem;

    span {
        cursor: pointer !important;
    }
}

.success-link {
    cursor: default;

    button {
        cursor: inherit;
    }
}

.variants-admin {
    .list-group-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

}

.btn.btn-disabled {
    background-color: #848484;
    color: #fff;
}

.form-label.error {
    color: red;
    font-weight: 600;
}

.form-control.error {
    border: 2px solid red;
}

.input-not-filled {
    margin-top: 6px;
    color: red;
}

.imagePosition.trash {
    background-color: transparent;

    svg path {
        fill: red;
    }
}

.searchCat {
    list-style-type: none;
    padding-left: 0;
}

.bigimage {
    background-color: red;
    width: 200px;
    height: 200px;
    display: block;
    background: rgba(0, 0%, 100%, .3);
    border: 1px solid #ccc;
    position: relative;
}

#myPortal {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1;
    max-height: 556px;
}

.myPortalContainer {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.imgg {
    display: flex;
    justify-content: center;

    img {
        max-height: 416px;
        max-width: 416px;
        width: auto !important;
        margin: 0 auto;
    }

}

#slider .slick-slide {
    position: relative;
}

#slider .slick-slide>div {
    transform: translate(25%, 0);
}

.aleert {
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 32%;
    text-align: center;
}

.ul {
    padding-left: 0;
    list-style: none;
}

.collection-screen {
    display: flex;
    align-items: center;

    a,
    button,
    select,
    input {
        margin-right: 1rem;
    }

    select,
    input {
        width: 20%;
    }

    .changes {
        position: absolute;
        right: 0;
    }
}

.updated {
    font-size: 0.9rem;
    font-style: italic;
}

.form-control:focus {
    box-shadow: unset;
    border-color: #e14547c2;
}

.btnCommon {
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: .5rem 1.5rem;
    border-radius: .25rem;
    color: #fff;
    background-color: #e42529;
    border: unset;
    transition: all .3s ease-in-out;

    &:hover {
        background-color: #222222 !important;
        color: #fff;
    }
}

.btnCommon--black {
    color: #222;
    background-color: #fff;
    border: 1px solid #fff;
}

.btnCommon--green {
    color: #fff;
    background-color: #198754;
    border: 1px solid #198754;
}


.minHeight100 {
    min-height: 100vh;
}

.headerMenu {
    .navbar-nav {
        gap: 1rem;

        li {
            a {
                padding: 0.5rem 1rem;
                font-size: 0.9rem;
                font-weight: 400;
                line-height: 100%;

                &:hover {
                    background-color: #ffc3c4;
                }
            }
        }
    }
}

.categoryMenu__list {
    border: 1px solid #dedede;
    list-style: none;
    padding: 0;
    margin-bottom: 5rem;

    li {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        border-bottom: 1px solid #dedede;
        padding: 1rem;
        margin: 0;
        line-height: 100%;

        span {
            display: block;
            font-size: .9rem;
        }

        a {
            width: fit-content;
            display: block;
            // background-color: red;
        }
    }
}

.commonTitle--xl {
    padding: 0 0 .25rem 0;
    line-height: 100%;
    font-size: 1.75rem;
    letter-spacing: 0px;
    text-transform: capitalize;
    font-weight: 300;
    margin: 0;
    border-bottom: 1px solid #222;
    width: fit-content;
}

.commonTitle--wul {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 100%;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.productList__table {
    tr {

        th,
        td {
            font-size: .9rem;
        }
    }
}

.action__Btn {
    background-color: #000;
    transition: all .3s ease-in-out;

    i {
        color: #fff;
    }

    &:hover {
        background: transparent;

        i {
            color: #000;
        }
    }
}

.productPage__navbar {
    padding: 1.5rem 0;
    background-color: #ffe6e6 !important;

    .productPageNavbar__discardBtn {
        color: #fff;
        font-size: .9rem;
        font-weight: 500;
        padding: .5rem 1rem;
        background-color: #e42529;
        border: 1px solid #e42529;
        border-radius: .25rem;
        transition: all .3s ease-in-out;
        display: block;

        svg {
            width: 1rem;
            height: 1rem;
        }

        &:hover {
            transform: scale(1.10);
        }
    }
}

.productPageNavbar__actionBtnBox {
    display: flex;
    align-items: center;
    gap: 1rem;

    .productPageNavbar__actionBtn {
        font-size: .9rem;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: .5rem 1rem;
        background-color: #222;
        color: #fff;
        line-height: 100%;
        border-radius: .25rem;
        border: unset;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.info_txt {
    font-size: .8rem;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #222;
    padding: .25rem .5rem;
    display: block;
    width: fit-content;
    margin: 1rem 0;
}

.searchCat {
    border: 1px solid #dedede;
    margin: 1rem 0;
    padding: 0;
    border-radius: .25rem;

    li {
        border-bottom: 1px solid #dedede;
        line-height: 100%;
        font-size: .9rem;
        cursor: pointer;
        padding: .75rem 1rem;
        transition: all .2s ease;

        &:hover {
            background-color: #9b9b9b;
            color: #fff;
        }
    }
}

.table {

    td,
    th {
        font-size: .9rem;
        padding: .5rem;
    }
}

.pdp__accordion {
    margin: 2.5rem 0;

    .accordion-item {
        .accordion-button {
            padding-top: .5rem;
            padding-bottom: .5rem;
            font-size: 1rem;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1px;

            &:focus {
                box-shadow: unset;
                outline: unset;
                border-color: unset;
                background-color: unset;
                color: #222;
            }
        }

        .accordion-button:not(.collapsed) {
            background-color: transparent;
            color: #000;
            box-shadow: unset;
            border: unset;
        }

        .accordion-collapse {
            border-top: 1px solid #dedede;
        }
    }

    .accordion-body {
        .form-label {
            font-size: 1rem;
            font-weight: 500;
            text-transform: uppercase;
            color: #585858;
            letter-spacing: -0.5px;
            line-height: 100%;
        }
    }
}

.product__media {
    margin: 2rem 1rem;
    padding: 1rem 1rem 0 1rem;
    border: 1px solid #dedede;
    border-radius: .5rem;

    .singleMediaDiv {
        .singleMedia {
            padding: 2rem;
            border-radius: .5rem;

            .img-fluid {
                width: 100% !important;
                height: 4rem !important;
                object-fit: contain !important;
            }

            .mediaDeleteBtn {
                border-bottom: 1px solid #222;
            }
        }
    }
}

.variants-admin .list-group-item {
    align-items: center;
}

.custom-file-input {
    position: relative;
    display: inline-block;
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #222;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    overflow: hidden;
    width: 8rem;
    height: 100%;
    line-height: 100%;
}

.custom-file-input::before {
    content: 'Choose File';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    pointer-events: none;
}

.custom-file-input:hover {
    background-color: #b5b5b5;
    color: #222222;
}

.custom-file-input:active {
    background-color: #b5b5b5;
    color: #222222;
}

.custom-file-input:focus {
    outline: none;
    box-shadow: 0 0 0 3px #b5b5b5;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::file-selector-button {
    visibility: hidden;
}

.highlight__ul {
    padding-left: 1rem;

    li {
        font-size: .8rem;
        font-weight: 400;
        padding-bottom: .5rem;
        list-style: decimal;
    }
}

.pdpFeature__section {
    .card-body-list {
        padding: 0;
    }

    .featureCard__content {
        border-bottom: 1px solid #00000020;
        padding: 1rem;

        .cardBtn__box {
            display: flex;
            gap: 1rem;
            margin-top: 1rem;

            span {
                cursor: pointer;
            }
        }
    }
}