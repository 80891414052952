.titleBox {
    h1 {
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 7px;
        margin-bottom: 22px;
        font-size: 23px;
    }

}

.productCard {
    flex: 1 1 0;
    border: unset;

    .card-body {
        padding-bottom: 0px;
        padding-left: 0;
        padding-right: 0px;
    }

    img.productImg {
        width: auto;
        height: 14rem;
    }


    .product-rate {
        font-size: 20px;
        margin-top: 10px;
    }

    h4 {
        font-size: 0.9rem;
        letter-spacing: 0rem;
        margin-top: 20px;
        line-height: 22px;
        margin-bottom: 0px;
        font-weight: 500;
    }

    .card-title {
        margin-bottom: 0px;
    }

    .card-footer {
        border: unset;
        background-color: unset;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.defaultBlock {
    padding-top: 26px;
    padding-bottom: 13px;

    .splide__slide {
        display: flex;
    }
}